export default {
  message: {
    download: '下载',
    softwareDownload: '软件下载',
    deviceManagement: '远程运维',
    accountManagement: '账号管理',
    siginIn: '登录',
    signOut: '退出',
    register: '注册',
    chinese: '中文',
    english: '英文',
    changePwd: '修改密码',
    changeYourPwd: '修改密码',
    forgotPwd: '忘记密码',
    noAccount: '您是否有账户',
    goRegister: '注册',
    registerIde: '工程师账号注册',
    registerApp: '用户账号注册',
    company: '公司名称',
    name: '申请人姓名',
    phone: '手机号',
    email: '邮箱',
    forgotPwd: '重置密码',
    code: '验证码',
    currentPwd: '原密码',
    newPwd: '新密码',
    confirm: '再次输入',
    create: '新建',
    name2: '名称',
    createDate: '申请日期',
    status: '状态',
    processing: '申请中',
    done: '完成',
    operation: '操作',
    send: '发送',
    more: '更多版本',
    firmware: '固件下载',
    hcDownload: '软件下载',
    hcDescribe: '集成开发环境（简称：IDE）提供可视化编程，秉承所见即所得的设计理念，通过丰富且持续更新的各种资源库，助力工程师快速高效的完成控制项目的设计开发。',
    pc: 'PC客户端',
    pcDescribe: 'Windows版本智能系统控制程序。',
    androidDescribe: '安卓版本智能系统控制程序。',
    iosDescribe: 'IOS版本智能系统控制程序。',
    version: '版本',
    versionDescribe: '版本说明',
    releaseDate: '发布时间',
    more2: '更多',
    noData: '暂无数据',
    pleaseSelect: '请选择',
    total: '设备总数',
    online: '在线设备',
    offline: '离线设备',
    online2: '在线',
    offline2: '离线',
    all: '全部',
    offlineTime: '离线时间',
    model: '设备类型',
    devices: '设备列表',
    model2: '型号',
    firmwareVersion: '固件版本',
    intranetIP: '内网IP',
    internetIP: '外网IP',
    access: '管理',
    other: '其他',
    percentage: '百分比',
    uncheck: '申请中',
    agree: '完成',
    disagree: '未通过',
    unknown: '未知',
    noMatch: '无匹配选项',
    product: '产品型号',
    downloadOld: '注意！2.0.0版本不能直接升级到2.1.2，需先升级到2.1.1<br/>升级前请先重置系统，确保磁盘空间足够',
    Continue: '继续下载',
    Download2_1_1: '下载2.1.1',
    noVersion: '无对应版本！',
    UITemplate: 'UI模板',
    DriverCode: '驱动源码',
    DrivePackage: '驱动包',
    MyResources: '我的资源',
    PublishDate: '发布日期',
    Default: '默认',
    AllType: '全部分类',
    AllStatus: '全部状态',
    Reject: '审核退回',
    ToSubmit: '待提交',
    Checking: '审核中',
    Deployed: '已发布',
    LookMore: '查看更多',
    Found: '共',
    Results: '条',
    NoMore: '没有更多了',
    NoTemplate: '暂无此类资源',
    Update: '修改',
    Withdraw: '撤回',
    Submit: '提交',
    NoImg: '缩略图为空',
    Delete: '删除',
    OperationFailed: '操作失败!',
    BaseInfo: "基础信息",
    Interfaces: "接口",
    Features: "功能项",
    Feature: "功能项",
    DriverEvent: "驱动事件",
    IRCommands: "红外指令",
    VersionNote: "版本描述",
    Syntax: "语法",
    Parameters: "参数",
    Result: "返回值",
    Directory: '文档目录',
    Description: '描述',
    Back: '返回',
    WithdrawPrompt: '该资源将被撤回，是否继续？',
    Confirm: '确认',
    Save: '保存',
    Cancel: '取消',
    Tips: '提示',
    OperationSucceeded: '操作成功!',
    DeletePrompt: '该资源将被删除，是否继续？',
    Name: '名称',
    PleaseEnterName: '请输入名称',
    PleaseEnterBrand: '请输入品牌',
    PleaseEnterDeviceName: '请输入设备名称',
    Version: '版本号',
    PleaseEnterVersion: '请输入版本号',
    Keywords: '关键字',
    PleaseEnterKeywords: '请输入关键字',
    Author: '发布人',
    PleaseEnterAuthor: '请输入发布人',
    Brand: '品牌',
    DeviceName: '设备名',
    ReleaseScope: '发布范围',
    IllegalCharacter: '非法的字符：',
    EnterUptoFiveKeywords: '最多5个关键字',
    KeywordsLength20: '单个关键字超长',
    DriverUpdate: '驱动修改',
    PackageUpdate: '驱动包修改',
    UITemplateUpdate: 'UI模板修改',
    Open: '公开',
    OnlyVisible: '仅自己可见',
    ResourceDownload: '资源下载',
    Private: '私有',
    Close: '关闭',
    Authorization: '授权管理',
    NewCode: '生成授权码',
    GenerationDate: '生成日期',
    Generate: '开始生成',
    MAC: 'MAC地址',
    Deadline: '授权截止日期',
    ExpirationDate: '授权截止',
    AuthorizationDays: '授权天数',
    AuthorizationCode: '授权码',
    Copy: '复制',
    Start: '开始生成',
    PersonalCenter: '个人中心',
    UpdateAuthorization: '修改授权',
    Company: '公司',
    FullName: '姓名',
    Support: '技术支持',
    YourAccount: '账号',
    ExpireInDays: '天后到期，请进入“个人中心”完成账号续期。',
    LessThan1Day: '账号有效期不到1天，请进入“个人中心”完成账号续期。',
    AccountExpired: "账号已到期，请进入“个人中心”完成账号续期。",
    Go: '续期',
    MaximumInstallations: '授权安装',
    MaximumInstallationsCount: '申请授权安装数',
    Note: '申请原因',
    PCs: '台',
    AllPCs: '共',
    used: '已安装',
    CurrentExpirationDate: '当前授权截止日期',
    CurrentInstallations: '当前已授权',
    SubmitApplication: '提交申请',
    Processing: '授权申请中',
    notActive: '未激活',
    Type: '类型',
    Documentation: '文档说明',
    File: '文件名',
    LicenseMode: '授权模式',
    Limited: '有限授权',
    Perpetual: '永久授权',
  },
  rules: {
    account: '请输入账号',
    password: '请输入密码',
    captcha: '请输入验证码',
    company: '请输入公司名称',
    name: '请输入姓名',
    phone: '请输入手机号',
    phoneFormat: '请输入正确格式的手机号',
    email: '请输入邮箱',
    emailFormat: '请输入正确格式的邮箱',
    emailCode: '请输入邮箱验证码',
    confirm: '请再次输入密码',
    currentPwd: '请输入原密码',
    newPwd: '请输入新密码',
    differentPwd: '两次密码不一致',
    pwdLength: '密码长度不能小于6位数',
    pwdType: '密码不能输入中文',
    ExpirationDate: '请选择申请截止日期',
    PCs: '请输入台数',
    Note: '请输入申请原因',
    MAC: '请输入MAC地址',
    MACFormat: '请输入正确的格式，例如：00:0C:29:CA:E2:60'
  },
  submit: {
    siginIn: '登录',
    register: '注册',
    changePwd: '修改密码',
    forgotPwd: '重置密码',
  },
  success: {
    siginIn: '登录成功！',
    signOut: '退出成功！',
    register: '注册申请已经提交，后台处理中。',
    changePwd: '修改成功！',
    sendEmail: '验证码刚刚已经发送到了',
    Copied: '复制成功！'
  },
  failed: {
    siginIn: '登录失败！',
    signOut: '退出失败！',
    register: '注册失败！',
    getData: '获取数据失败！',
    changePwd: '修改失败！',
    sendEmail: '发送验证码邮件失败！',
    Copied: '复制失败！',
    MaximumIs: '此项值最大为',
    ServiceException: '服务异常，请稍后再试'
  }
}